.top_gradient {
  position: absolute;
  width: 100%;
  height: 8.25rem;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, #FFFFFF 22.4%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
}

.yandex_map {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.ymap_icon {
  position: absolute;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ymap_icon_caption {
  display: flex;
  flex-direction: row;
  justify-content: center;

  background: #5364ff;
  border-radius: 8px;

  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 2.4rem;

  padding: 0 0.5rem;
  height: 2.4rem;
  width: 100%;
} 

.ymap_icon_distance {
  color: rgba(255, 255, 255, 0.5);
}

.ymap_icon_price {
  margin-left: 0.5rem;
}

.ymap_icon_placemark {
 position: relative;
 top: 0.25rem;
}