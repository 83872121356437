
.screen_tab_container {
  position: relative;
}

.screen_tab_container a {
  text-decoration: none;
}

.screen_tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background: #fff;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 0 1.6rem;
  height: 4.8rem;
}

.screen_tab_title {
  color: #000;
  font-weight: 500;
  line-height: 1.4rem;
}

.screen_tab_warning {
  color: red;
  font-size: 0.94rem;
  line-height: 1.4rem;
}

.screen_tab_icon {
  position: absolute;
  right: 1.6rem;
}