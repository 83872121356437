.statistic_page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

.statistic_header {
  background: #fff;
  padding: 1.6rem 1.6rem 1rem;
}

.statistic_period_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.statistic_period_title {
  font-weight: 500;
  color: #888E99;
}

.statistic_period_info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  color: #5364ff;
}

.statistic_container {
  background: #ecedef;
  height: 100%;
}

.statistic_money {
  padding: 1.6rem;
}

.statistic_debit_credit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 -0.8rem;
}

.statistic_item {
  margin: 0 0.8rem;
}

.statistic_item_name {
  color: #888e99;
}

.statistic_item_value {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.5rem;
}

.statistic_debit .statistic_item_value,
.statistic_credit .statistic_item_value {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3rem;
}

.statistic_debit .statistic_item_value,
.statistic_price .statistic_item_value {
  color: #5364ff;
}

.statistic_price,
.statistic_distance {
  margin-top: 1.6rem;
}

.statistic_truck {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.6rem;
}