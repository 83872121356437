.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  height: 3.4rem;

  cursor: pointer;

  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-decoration: none;

  box-sizing: border-box;
  border-radius: 8px;
}

.btn svg {
  margin-left: 10px;
  margin-top: 5px;
}

.btn_default {
  background: #ffffff;
  color: #000;
}

.btn_referal {
  background: linear-gradient(90deg, #FF8C22 -0.15%, #FF5D53 99.85%);
  border: 3px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}

.btn_grey {
  background: #ecedef;
  color: #000;
}