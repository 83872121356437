.check_phone_window {
  height: 100vh;
  width: 100vw;
  background: #fff;
}

.check_phone_container {
	display: flex;
	flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: calc(100% - 4.8rem);
  margin: 0 2.4rem;
}

.check_phone_title {
	font-size: 2.1rem;
	font-weight: 500;
	line-height: 2.5rem;
	margin-top: 30%;
}

.check_phone_text_bold {
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 1.33rem;
	align-self: flex-start;
}

.check_phone_list tr {
	height: 4rem;
}

.check_phone_list_bullet {
	vertical-align: top;
	width: 2.5rem;
}

.check_phone_list_item {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	vertical-align: top;
	padding-top: 0.15rem;
}

.check_phone_start_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.4rem 3.5rem;
	background: #5364ff;
	border-radius: 0.5rem;
	margin-top: 20%;
	margin-bottom: 15%;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.check_phone_start_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.check_phone_start_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.check_phone_label {
	width: 100%;
	margin-top: 60%;
}

.check_phone_label_text {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
}

.check_phone_input {
	width: calc(100% - 4.8rem);
	margin: 1.33rem 2.4rem;
	height: 3.33rem;
	font-size: 1.1rem;
	color: #000;
	line-height: 1.3rem;
	text-align: center;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
}

.check_phone_input:focus {
	border: 2px solid #5364ff;
	outline: none;
}

.check_phone_check {
	position: absolute;
	top: 2.5rem;
	right: 20%;
}

.check_phone_check path {
	stroke: #00bb40;
}

.check_phone_input_container {
	position: relative;
}

.check_phone_next_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	margin-top: 50%;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.check_phone_next_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.inactive {
	opacity: 0.5;
	pointer-events: none;
}

.check_phone_error {
	position: absolute;
	width: 100%;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
	color: #ff0000;
}

.check_phone_text_grey {
	position: absolute;
	width: 100%;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
	color: #888e99;
}
