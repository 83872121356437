.status_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;

  height: 3.25rem;
  padding: 0px 1rem;

  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.status_btn_guest {
  background: #5364FF;
}

.status_btn_work {
  background: #00BB40;
}

.status_btn_dayoff {
  background: #888E99;
}

.status_btn_text {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.466rem;

  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 auto;
}

.status_btn_icon {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 0.4rem 0.5rem;
  margin-left: 0.5rem;
}