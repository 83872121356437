.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1.2rem;
}

.container_title {
	width: 100%;
	padding: 0 1.1rem 0 1.6rem;
	background: #888e99;
}

.container_title_text {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 2.8rem;
	color: #fff;
}

.container_item {
	width: 100%;
	height: 4.8rem;
	padding: 0 1.1rem 0 1.6rem;
	background: #fff;
	border-bottom: 1px solid #ecedef;
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
	align-items: center;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.container_item:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.container_item_description {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.container_item_name {
	font-size: 1.1rem;
	font-weight: 400;
	color: #000;
	line-height: 1.5rem;
}

.container_item_name_blue {
	font-size: 1.1rem;
	font-weight: 500;
	color: #5364ff;
	line-height: 1.5rem;
}

.container_item_value {
	font-size: 0.9rem;
	font-weight: 400;
	color: #888e99;
	line-height: 1.5rem;
}

.container_item_action {
	font-size: 1.1rem;
	font-weight: 400;
	color: #888e99;
	line-height: 1.5rem;
}

.container_expand {
	width: 0.5rem;
	height: 0.5rem;
	margin-left: auto;
	border-style: solid;
	border-color: #c6cad2;
	border-width: 3px 3px 0 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.container_add {
	width: 1rem;
	height: 1rem;
	position: relative;
	margin-left: auto;
}

.container_add_h {
	position: absolute;
	width: 100%;
	height: 50%;
	left: 0;
	top: 50%;
	border-top: 2px solid #c6cad2;
}

.container_add_v {
	position: absolute;
	width: 50%;
	height: 100%;
	left: calc(50% - 1px);
	top: 0;
	border-left: 2px solid #c6cad2;
}

.container_add::before {
	content: '';
	position: relative;
	left: -1.8rem;
	top: 0;
	width: 1.1rem;
	height: 1.1rem;
	background: #5364ff;
	border-radius: 50%;
	display: none;
}

.container_item:first-child .container_add::before {
	display: block;
}

.container_item:last-child {
	border-bottom: none;
}

.container_check {
	width: 2rem;
	height: 2rem;
	margin-left: auto;
}

.container_check .checkbox_mark {
	border-radius: 50%;
}

.container_check .checkbox_mark::after {
	top: 0.5rem;
}

.container_item_nocircle .container_add::before {
	display: none!important;
}
