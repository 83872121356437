.bank_window {
  height: 100%;
  width: 100%;
  background: #fff;
}

.bank_container {
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(100% - 4.8rem);
  margin: 0 2.4rem;
}

.bank_title {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.6rem;
	text-align: left;
	width: 100%;
	margin: 5.5rem 0 0.8rem 0;
}

.bank_input {
	width: 100%;
	margin: 0.4rem 0;
	line-height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
}

.bank_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.bank_input_complete {
	background: #ecedef;
}

.bank_autofill.bank_input_complete {
	background: #fff1cf;
}

.bank_input_text {
	width: calc(100% - 2rem);
	margin: 0 1rem;
	font-size: 1.1rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
}

.bank_footer {
	margin: auto 0 3.33rem 0;
}

.bank_link {
	width: 100%;
	color: #888e99;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: underline;
	margin: 2rem 0;
}

.bank_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.bank_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.bank_button_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}
