.orders_window {
  position: fixed;
  left: 0;
  bottom: 0;

  width: 100vw;

  background: #ECEDEF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0 0;

  z-index: 15;
}

.orders_window_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  position: absolute;
  top: -1.25rem;
  left: 0;
  width: 100vw;
}

.orders_window_btn {
  background: #a8a8a8;
  opacity: 0.6;
  border-radius: 13px;
  height: 8px;
  width: 50px;
}

.orders_general_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1.4rem 1rem 1rem;

  width: 100%;
}

.orders_amount {
  box-sizing: border-box;

  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.94rem;
  letter-spacing: -0.408px;
  color: #5364FF;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.orders_sum {
  box-sizing: border-box;

  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.408px;
  color: #000000;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0.25rem 0px;
}

.orders_window_title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.94rem;
  letter-spacing: -0.408px;
  color: #000;
}

.orders_list_horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  max-width: 100%;

  overflow: scroll;
  padding: 1rem 0;
}

.orders_list_vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  overflow: scroll;
  padding: 1rem;

  width: 100%;
}

.orders_tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
}

.orders_tab {
  position: relative;

  cursor: pointer;
  background: #c6cad2;
  border-radius: 8px;
  padding: 0.4rem 0.8rem;

  font-weight: 500;
  line-height: 1.466rem;
  color: #fff;

  text-transform: uppercase;
  margin-left: 0.8rem;
}

.orders_tab:first-child {
  margin-left: 0;
}

.orders_tabs .active {
  background: #3b3f49;
}

.unviewed:before {
  content: '';

  position: absolute;
  top: -0.25rem;
  right: -0.25rem;

  background: #5364ff;
  border-radius: 50%;

  height: 1rem;
  width: 1rem;
}

.orders_list_horizontal .order_block {
  margin: 0 0.4rem;
  width: 210px;
}

.orders_list_horizontal .order_block:first-child {
  margin-left: 1rem;
}

.orders_list_horizontal .order_block:last-child {
  margin-right: 1rem;
}

.orders_list_horizontal .order_block_info_right {
  width: 3.25rem;
}

.orders_list_vertical .order_block {
  margin: 0.4rem 0;
  width: 100%;
}

.orders_list_vertical .order_block:first-child {
  margin-top: 0;
}

.orders_list_vertical .order_block:last-child {
  margin-bottom: 0;
}

.order_block {
  position: relative;
  box-sizing: border-box;

  background: #FFFFFF;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.order_block_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  padding: 0.8rem;
}

.order_block_info_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.order_block_title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.46rem;
  letter-spacing: -0.408px;
  color: #000000;
}

.order_block_route {
  font-weight: 500;
  line-height: 1.2rem;
  color: #888e99;
  margin-top: 0.35rem;
}

.orders_list_vertical .order_block_distantion {
  margin-top: 0.35rem;
}

.order_block_distantion {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  letter-spacing: -0.408px;
  color: #888E99;
}

.order_block_datetime {
  margin-top: 0.35rem;

  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.408px;
  color: #5364FF;
}

.order_block_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 0.8rem;
}

.order_block_conditions ul {
  list-style:none;
  margin: 0;
  padding: 0;
  display: flex;
}

.orders_list_horizontal .order_block_conditions ul {
  flex-direction: column;
}

.orders_list_vertical .order_block_conditions ul {
  flex-direction: row;
}

.orders_list_vertical .order_block_conditions ul li {
  margin: 0 0.4rem;
}

.orders_list_vertical .order_block_conditions ul li:first-child {
  margin-left: 0;
}

.orders_list_vertical .order_block_conditions ul li:last-child {
  margin-right: 0;
}

.order_block_conditions ul li {
  font-size: 1rem;
  color: #888e99;
}

.order_block_conditions ul li:before {
  content: '';
  display: inline-block;
  height: 2px;
  width: 2px;
  vertical-align: middle;
  border-radius:50%;
  background-color: #888e99;
  margin-right: 0.35rem;
}

.order_block_id {
  color: #888e99;
}