@keyframes flashAnimation {
	from { opacity: 0.75; }
	to { opacity: 0; }
}

.camera_wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.camera_video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.camera_video::-webkit-media-controls-play-button {
	display: none;
	-webkit-appearance: none;
}

.camera_overlay_container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.camera_subtitle {
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 4rem;
	color: #fff;
	z-index: 2;
	height: 4rem;
}

.camera_overlay {
	position: relative;
}

.camera_box {
	border: 2px solid #5364ff;
	border-radius: 0.5rem;
	z-index: 1;
	box-shadow: 0 0 0 600px rgba(59, 63, 73, 0.8);
}

.camera_canvas {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.camera_flash {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: #fff;
}

.camera_flashing {
	animation: flashAnimation 750ms ease-out;
}

.camera_image_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 6rem;
	z-index: 2;
}

.camera_image {
	height: 4rem;
	width: auto;
	margin: 0.5rem 0.25rem;
}

.camera_capture {
	margin-top: auto;
	margin-bottom: 6%;
	width: 4.4rem;
	height: 4.4rem;
	position: relative;
	border: 4px solid #fff;
	border-radius: 50%;
	z-index: 2;
}

.camera_capture_inner {
	position: absolute;
	width: 3.6rem;
	height: 3.6rem;
	background: #fff;
	border-radius: 50%;
	left: calc(50% - 1.8rem);
	top: calc(50% - 1.8rem);
}
