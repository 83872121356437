.license_window {
  height: 100vh;
  width: 100%;
  background: #fff;
  position: relative;
}

.license_container {
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
}

.license_title {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.6rem;
	text-align: left;
	width: 100%;
	margin: 5.5rem 0 0.8rem 0;
}

.license_input {
	width: 100%;
	margin: 0.4rem 0;
	line-height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
}

.license_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.license_input_complete {
	background: #ecedef;
}

.license_autofill.license_input_complete {
	background: #fff1cf;
}

.license_input_text {
	width: calc(100% - 2rem);
	margin: 0 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
}

.license_input_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.license_input_short {
	width: 48%;
}

.license_select_placeholder {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	font-weight: 400;
	color: #888e99;
	opacity: 1;
}

.license_select_wrapper div {
	padding: 0;
	border-radius: 0.5rem;
}

.license_textarea {
	width: calc(100% - 2rem);
	margin: 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	line-height: 1.2rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
	resize: none;
}

.license_photo_container {
	width: 100%;
	height: 6.66rem;
	margin: 0.4rem 0;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.license_photo_button {
	width: 100%;
	height: 6.66rem;
	margin: 0.4rem 0;
	line-height: 1.7rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.license_photo_button:active {
	border: 2px solid #5364ff;
	outline: none;
}

.license_photo_button_text {
	width: calc(100% - 2rem);
	margin: 1rem;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 2.2rem;
	color: #888e99;
	text-align: center;
}

.license_photo {
	height: 4rem;
	width: auto;
	margin: 0.5rem 0.25rem;
}

.license_footer {
	width: 100%;
	margin: auto 2.4rem 3.33rem 2.4rem;
}

.license_link {
	width: 100%;
	color: #888e99;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: underline;
	margin: 2rem 0;
}

.license_button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.license_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.license_button_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}
