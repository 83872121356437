@media screen and (min-width: 375px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) {
  html {
    font-size: 13px;
  }
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

body {
  background: #ecedef;
  margin: 0;
  font-family: 'Fira Sans';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
}

.page_back_link {
  position: absolute;
  top: 1.55rem;
  left: 1.6rem;
}

.page_title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.466rem;
}

.window_close_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 1.33rem;
  right: 1.33rem;
  background: #c6cad2;
  border-radius: 50%;
  height: 2.4rem;
  width: 2.4rem;
}

.window_close_btn svg {
  position: relative;
  top: 0.1rem;
}