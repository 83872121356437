.passport_window {
  height: 100%;
  width: 100%;
  background: #fff;
  position: relative;
}

.passport_container {
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
}

.passport_title {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.6rem;
	text-align: left;
	width: 100%;
	margin: 5.5rem 0 0.8rem 0;
}

.passport_input {
	width: 100%;
	margin: 0.4rem 0;
	line-height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
}

.passport_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.passport_input_complete {
	background: #ecedef;
}

.passport_input_text {
	width: calc(100% - 2rem);
	margin: 0 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
}

.passport_input_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.passport_input_short {
	width: 48%;
}

.passport_textarea {
	width: calc(100% - 2rem);
	margin: 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	line-height: 1.2rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
	resize: none;
}

.passport_check {
	position: absolute;
	top: 25%;
	right: 0.8rem;
}

.passport_check div::after {
	top: -0.8rem;
}

.passport_photo_container {
	width: 100%;
	height: 6.66rem;
	margin: 0.4rem 0;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.passport_photo_button {
	width: 100%;
	height: 6.66rem;
	margin: 0.4rem 0;
	line-height: 1.7rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.passport_photo_button:active {
	border: 2px solid #5364ff;
	outline: none;
}

.passport_photo_button_text {
	width: calc(100% - 2rem);
	margin: 1rem;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 2.2rem;
	color: #888e99;
	text-align: center;
}

.passport_photo {
	height: 4rem;
	width: auto;
	margin: 0.5rem 0.25rem;
}

.passport_footer {
	width: 100%;
	margin: auto 2.4rem 3.33rem 2.4rem;
}

.passport_link {
	width: 100%;
	color: #888e99;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: underline;
	margin: 2rem 0;
}

.passport_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.passport_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.passport_button_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}
