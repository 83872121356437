.cancel_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.33rem;
  right: 1.33rem;
  background: #c6cad2;
  border-radius: 50%;
  height: 2.4rem;
  width: 2.4rem;
  z-index: 100;
}

.cancel_btn svg {
  position: relative;
  top: 0.1rem;
}
