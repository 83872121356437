.order_window_links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  padding: 0 1rem 1rem;
  width: 100vw;
}

.order_window_btn {
  font-size: 1rem;
  line-height: 1.2rem;
}

.order_window_btn_referals {
  width: calc(70% - 0.4rem);
}

.order_window_btn_chats {
  width: calc(30% - 0.4rem);
}