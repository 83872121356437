.vehicle_window {
  height: 100vh;
  width: 100%;
  background: #fff;
  position: relative;
}

.vehicle_container {
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(100% - 5.2rem);
  margin: 0 2.6rem;
}

.vehicle_title {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.6rem;
	text-align: left;
	width: 100%;
	margin: 10% 0 1.2rem 0;
}

.vehicle_title_big {
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 2rem;
	text-align: left;
	width: 100%;
	margin: 10% 0 1.2rem 0;
}

.vehicle_input_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}


.vehicle_input {
	width: 100%;
	margin: 0.4rem 0;
	line-height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
}

.vehicle_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.vehicle_input_complete {
	background: #ecedef;
}

.vehicle_input_text {
	width: calc(100% - 2rem);
	margin: 0 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
}

.vehicle_input_short {
	width: 48%;
}

.vehicle_input_tiny {
	width: 31%;
}

.vehicle_select_nodata {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	font-weight: 400;
	text-align: center;
	width: 100%;
	color: #c6cad2;
	margin: 1rem 0;
}

.vehicle_select {
	width: 48%;
	margin: 0.4rem 0;
	line-height: 3.33rem;
	border-radius: 0.5rem;
	background: #5364ff;
	position: relative;
	border: none;
	outline: none;
}

.vehicle_select div {
	padding: 0;
	border-radius: 0.5rem;
}

.vehicle_select_complete {
	background: #888e99;
}

.vehicle_select_color {
	color: #fff;
}

.vehicle_select_text {
	color: #fff;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	font-weight: 400;
}

.vehicle_select_item {
	font-size: 1.1rem;
	line-height: 1.6rem;
}

.vehicle_select_item:first-child {
	margin-top: 0.5rem;
}

.vehicle_select_item:last-child {
	margin-bottom: 0.5rem;
}

.vehicle_select_placeholder {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	font-weight: 400;
	color: #fff;
}

.vehicle_chs_item {
	width: calc(100% + 1.5rem);
	margin: 0.25rem -0.75rem;
}

.vehicle_select_checkbox_area {
	width: 1.6rem;
}

.vehicle_select_checkbox {
	width: 1.2rem;
	height: 1.2rem;
}

.vehicle_select_label {
	font-size: 0.85rem;
}

.vehicle_select_input {
	margin-left: 0.25rem;
	width: calc(100% - 0.5rem);
	border: 1px solid #c6cad2;
	border-radius: 0.25rem;
	font-family: 'Fira Sans';
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 0.85rem;
	padding: 0.2rem 0.5rem;
}

.vehicle_select_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.vehicle_select_input_label {
	margin-left: 0.25rem;
	font-family: 'Fira Sans';
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 0.85rem;
}

.vehicle_photo_container {
	width: 100%;
	height: 6.66rem;
	margin: 0.4rem 0;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.vehicle_photo_button {
	width: 100%;
	height: 6.66rem;
	margin: 0.4rem 0;
	line-height: 1.7rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.vehicle_photo_button:active {
	border: 2px solid #5364ff;
	outline: none;
}

.vehicle_photo_button_text {
	width: calc(100% - 2rem);
	margin: 1rem;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 2.2rem;
	color: #888e99;
	text-align: center;
}

.vehicle_photo {
	height: 4rem;
	width: auto;
	margin: 0.5rem 0.25rem;
}

.vehicle_table {
	width: 100%;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	line-height: 1.33rem;
	font-weight: 400;
	color: #000;
	margin: 0.8rem 0;
}

.vehicle_table_row {
	height: 3rem;
}

.vehicle_checkbox {
	width: 2.5rem;
}

.vehicle_footer {
	width: 100%;
	margin: auto 2.4rem 3.33rem 2.4rem;
}

.vehicle_link {
	width: 100%;
	color: #888e99;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: underline;
	margin: 2rem 0;
}

.vehicle_button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.vehicle_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.vehicle_button_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.vehicle_loader {
	position: absolute;
	left: 50%;
	top: 25%;
	transform: translateX(-50%);
}

.vehicle_select_wrapper div {
	padding: 0;
	border-radius: 0.5rem;
}

.vehicle_final_back {
	margin-top: 10%;
	margin-left: 1.6rem;
	align-self: flex-start;
	width: 0.8rem;
	height: 0.8rem;
	border-style: solid;
	border-color: #888e99;
	border-width: 0 0 3px 3px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.vehicle_final_window {
  height: 100vh;
  width: 100vw;
  background: #ecedef;
  position: relative;
}

.vehicle_final_container {
	display: flex;
	flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.vehicle_final_title {
	text-align: left;
	width: 100%;
	margin-top: 2rem;
	padding: 0 1.6rem;
}

.vehicle_final_title_name {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.vehicle_final_title_type {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	color: #888e99;
	margin: 0.67rem 0;
}

.vehicle_final_container_item {
	width: 100%;
	height: 4.8rem;
	padding: 0 1.1rem 0 1.6rem;
	background: #fff;
	border-bottom: 1px solid #ecedef;
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
	align-items: center;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.vehicle_final_container_wrapper {
	width: 100%;
	margin: 3.2rem 0;
}

.vehicle_final_container_item:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.vehicle_final_container_item:last-child {
	border-bottom: none;
}

.vehicle_final_container_item_text {
	font-size: 1.1rem;
	font-weight: 400;
	color: #000;
	line-height: 1.5rem;
}

.vehicle_final_expand {
	width: 0.5rem;
	height: 0.5rem;
	margin-left: auto;
	border-style: solid;
	border-color: #c6cad2;
	border-width: 3px 3px 0 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
